import { defineStore } from 'pinia'

export const useTypeStore = defineStore('typeStore', {
  state: () => ({
    selected: {},
  }),

  actions: {
    async fetches(params: any) {
      return await useApi<any>('/types', {
        query: { params },
      })
    },

    async fetchBy(params: any) {
      return await useApiPublic<any>(createUrl('/types/category', {
        query: params,
      }))
    },

    async add(data: any) {
      return await $api('/types', {
        method: 'POST',
        body: data,
      })
    },
    async update(data: any) {
      return await $api(`/types/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },
    async delete(id: number) {
      return await $api(`/types/${id}`, {
        method: 'DELETE',
      })
    },
    async download(data: any) {
      return await $api<any>('/types/download', {
        params: data,
      })
    },

    async upload(data: any) {
      return await $api('/types/upload', {
        method: 'POST',
        body: data,
      })
    },
  },
})
